.loaderdiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFF0E9;
    z-index: 1000000
}

.loaderdiv>img {
    width: 250px;
    animation: animate 4s ease-in-out infinite
}

@keyframes animate {
    0% {
        opacity: 1;
        scale: 0.5;
    }

    50% {
        opacity: 1;
        scale: 1;
    }

    100% {
        opacity: 1;
        scale: 0.5;
    }


}