.inputreply {
    width: 93%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.205);
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 2px 10px;
    margin: 10px 5px;
    float: right;
    outline: none;
}