.notificationmodal_content {
    display: block;
    position: fixed;
    top: 8vh;
    right: 2.5%;
    background-color: #fff;
    color: var(--black);
    border-radius: 5px;
    min-width: 320px;
    padding-top: 15px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.473);
    z-index: 1000000;
}

.notification_drop {
    width: 15px;
    height: 15px;
    background-color: #fff;
    position: absolute;
    top: -1.5%;
    right: 17%;
    transform: rotate(45deg);
    box-shadow: -1px -1px 1px 0px rgba(0, 0, 0, 0.205);

}

.nf_heading {
    padding: 10px;
    padding-top: 0%;
    font-size: 18px;
    border-bottom: 1px solid rgba(240, 239, 239, 0.915)
}

.notification_detail {
    border-bottom: 1px solid rgba(212, 210, 210, 0.465);
    cursor: pointer;
}

.notification_detail:hover {
    background-color: #ebeaea;
}

.nf_title {
    padding: 7px;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
}

.nf_msg {
    font-size: 14px;
    margin-left: 10px
}

.nf_time {
    font-size: 12px;
    margin: 5px;
    margin-left: 10px;
    color: rgb(224, 223, 223)
}