/* new css */
.Add_button {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    border: 1px solid var(--red);
    color: var(--red);
    width: max-content;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.interestlist_img {
    width: 40px;
    height: 40px
}

.modalinterest {
    width: 40%;
    background-color: #fff;
    outline: none;
    border: none;
    border-radius: 5px;
    position: absolute;
    top: 30%;
    left: 30%;
    padding: 20px;
}

.interest_data {
    display: flex;
    gap: 20px;
    align-items: center;
}

.addimginterest {
    display: none;
}

.uploadint {
    border: 1px dashed rgba(0, 0, 0, 0.205);
    display: flex;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
}

.previewimgint {
    position: relative;
}

.interest_img {
    width: 40px;
    height: 40px;
    border: 1px dashed rgba(0, 0, 0, 0.205);
    padding: 7px
}

.previewint {
    font-size: 16px;
    position: absolute;
    top: -10%;
    right: -15%;
    cursor: pointer;
}

.inputinterests {
    width: 85%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.205);
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 2px 10px;
    margin: 10px 5px;
    float: right;
    outline: none;
}

.interest_addbutton {
    background: linear-gradient(90deg, var(--black), #218599);
    color: #fff;
    border-radius: 6px;
    height: 40px;
    padding: 10px 20px;
    border: none;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    cursor: pointer;
    margin: 10px 5px;
}

.modaldelete {
    width: 18%;
    background-color: #fff;
    outline: none;
    border: none;
    border-radius: 5px;
    position: absolute;
    top: 30%;
    left: 40%;
    padding: 20px;
}

.modaldelete>h3 {
    margin: 10px 0;
}



@media(max-width:500px) {
    .inputinterests {
        width: 85%;
    }

    .interest_addbutton {
        width: auto;
    }
}