* {
  margin: 0;
  padding: 0;
  font-family: Be Vietnam Pro;

}

body {
  margin: 0;
  color: var(--black) !important;
}

:root {
  --red: #FD8451;
  --red2: #FB4B50;
  --errorred: red;
  --lightred: #FFE2E3;
  --black: #075767;
  --blurblack: #00000045;
  --white: #ffffff;
  --blurwhite: #ffffffde;
  --yellow: #FEE24C;
  --grey: #D9D9D9;
  --grey1: #838383;
  --grey2: #F9F9F9;
  --grey3: #6F6F6F;
  --grey4: #BDBDBD;
  --grey5: #5F5F5F;
  --grey6: #f5f5f5;
}

@font-face {
  font-family: 'Code Next-Trial';
  src: url('./Fonts/CodeNext-Trial-Book.otf') format('truetype');
}