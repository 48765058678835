.App {
  background-color: var(--black);
}

.logo_img {
  margin-top: 10px;
  width: 100px;
  text-align: center;

}


.Auth-form-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.Auth-logo {
  text-align: center;
}

.Auth-form1 {
  width: 450px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  /* padding-top: 30px; */
  padding-bottom: 20px;
  border-radius: 8px;
  min-height: 350px;
  margin-top: 5vh;
  background-color: #FFF0E9;
  background-size: cover;
  border-left: 4px solid var(--red);
  border-top: 4px solid var(--red);
  border-right: 4px solid var(--black);
  border-bottom: 4px solid var(--black);

}

.Auth_formdata {
  margin: 15px 0;

}

.Auth-form-content1 {
  padding-left: 12%;
  padding-right: 12%;
  margin-top: 20px;

}



.Auth-form-title {
  font-size: 25px;
  color: var(--black);
  font-weight: 800;
  text-align: center;
  font-family: DM Sans;

}

.Auth_label {
  font-size: 15px;
  color: rgb(34, 34, 34);
}

.Auth_input {
  margin: 10px 0;
  width: 90%;
  padding: 12px;
  border-radius: 50px;
  outline: none;
  border: none;
  box-shadow: 0px 0px 30px 0px #00000012;
  border: 0.5px solid #e9e6e6;
}

.invalid {
  color: red;
  text-shadow: none;
  font-size: 13px;
}

.forgot-password {
  text-align: right;
  color: var(--red);
  font-size: 15px;
  cursor: pointer;
}

.Auth_button {
  width: 100%;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
  background-color: var(--red);
  padding: 10px 15px;
  margin: 25px 0;
  font-size: 15px;
  border: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  font-family: Poppins;
  font-size: 16px;
}

.otp_field {
  margin: 15px auto;
  width: 90%;
  justify-content: center;
}

@media(max-width:500px) {
  .Auth-form1 {
    width: 100%;
    margin: 0;
    padding-top: 200px;
    height: 74vh;
    border-radius: 0;
  }

  .Auth-form-title {
    margin-bottom: 50px;
  }


}