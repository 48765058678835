.menumodal_content {
    display: block;
    position: fixed;
    top: 8.5vh;
    right: 1.5%;
    background-color: #fff;
    border-radius: 5px;
    min-width: 200px;
    padding-top: 15px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.473);
    z-index: 1000000;
}

.menu_drop {
    width: 12px;
    height: 12px;
    background-color: white;
    position: absolute;
    top: -6%;
    right: 7%;
    transform: rotate(45deg);
    box-shadow: -1px -1px 1px 0px rgba(0, 0, 0, 0.205);
}


.menu_detail {
    cursor: pointer;
    padding: 10px 20px;
    display: flex;
    gap: 20px;
    color: rgb(116, 114, 114);
}

.menu_detail:hover {
    background-color: #ebeaea;
}