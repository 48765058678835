.notification_input_div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 15px 5%;
}

.notification_input {
    padding: 10px;
    width: 98%;
    border: 1px solid rgba(0, 0, 0, 0.205);
    border-radius: 6px;
    margin: 15px 0;
    outline: none;
}